import { Pipe, PipeTransform } from "@angular/core";
import { IMicrosoftSubscription } from "../../models/microsoft-subscriptions/microsoft-subscriptions.model";

@Pipe({ name: "showCycleAndTermPipe" })
export class ShowCycleAndTermPipe implements PipeTransform {
  transform (subscription: IMicrosoftSubscription): string {
    if (!subscription) return "";

    const commitment = subscription.termDuration;
    const billingCycle = subscription.billingCycle;

    return (
      BillingCycleNumber[ billingCycle ] + "/" + CommitmentNumber[ commitment ]
    );
  }
}

export enum BillingCycleNumber {
  Unknown = 0,
  None = 0,
  OneTime = 0,
  Monthly = 1,
  Annual = 12,
  Biennial = 24,
  Triennial = 36,
}

export enum CommitmentNumber {
  None = 0,
  P1M = 1,
  P1Y = 12,
  P3Y = 36,
}
